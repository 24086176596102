<template>
  <div>
    <el-main>
      <el-button type="primary" style="margin-bottom: 10px" size="small" @click="add">+添加子公司</el-button>
      <!-- 表单 -->
      <el-form class="el-form-search" label-width="140px">
        <el-form-item label="选择物业：">
          <el-select size="small" v-model="searchForm.property_id" clearable filterable style="width: 100%" >
            <el-option v-for="item in propertyList" :key="item.id" :label="item.property_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="子公司名称：">
          <el-input size="small" v-model="searchForm.subsidiary_name" placeholder="请输入子公司名称"></el-input>
        </el-form-item>
        <el-form-item label="负责人姓名：">
          <el-input size="small" v-model="searchForm.duty_name" placeholder="请输入负责人姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：">
          <el-input size="small" type="number" v-model="searchForm.mobile" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="子公司区域：">
          <el-cascader
          v-model="searchForm.area_id"
          :props="{ value: 'id', label: 'name', children: '_child' }"
          placeholder="请选择或搜索"
          clearable
          filterable
          :options="area"
          size="small"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="添加时间：">
          <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
      </el-form>
      <!-- 列表 -->
      <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="subsidiary_name" label="子公司名称" width="180" align="center"></el-table-column>
        <el-table-column prop="duty_name" label="负责人姓名" width="100" align="center"></el-table-column>
        <el-table-column prop="mobile" label="联系电话" width="110" align="center"></el-table-column>
        <el-table-column prop="area" label="子公司区域" width="150" align="center">
          <template v-slot="{ row }">
            <span>
              {{ row.province_name }}{{ row.city_name }}{{ row.area_name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="所在地址" align="center"></el-table-column>
        <el-table-column prop="service_order_amount" label="服务订单总金额" width="120" align="center">
          <template v-slot="{ row }">
            <span>
              ￥{{ row.service_order_amount }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="service_order_sum" label="服务订单总数" width="110" align="center"></el-table-column>
        <el-table-column prop="service_total_amount" label="服务收益总金额" width="120" align="center">
          <template v-slot="{ row }">
            <span>
              ￥{{ row.service_total_amount }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="property_name" label="物业名称" align="center"></el-table-column>
        <el-table-column prop="partner_count" label="合伙人数" width="80" align="center"></el-table-column>
        <el-table-column prop="create_time" label="添加时间" width="200" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
        </el-table-column>
        <el-table-column label="操作" width="150" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
            <el-button @click="remove(scope.row.id, scope.$index)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 翻页 -->
      <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    </el-main>
    <el-dialog :title="type ? '编辑子公司' : '添加子公司'" :visible.sync="showAdd" width="30%" :before-close="handleClose">
      <el-form ref="form" class="add" :model="addForm" :rules="rules" label-width="110px">
        <el-form-item label="选择物业：" required="true">
          <el-select v-model="addForm.property_id" placeholder="请选择或搜索" multiple clearable filterable style="width: 100%" >
            <el-option v-for="item in propertyList" :key="item.id" :label="item.property_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="子公司名称：" required="true">
          <el-input v-model="addForm.subsidiary_name"></el-input>
        </el-form-item>
        <el-form-item label="负责人姓名：" required="true">
          <el-input v-model="addForm.duty_name"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" required="true">
          <el-input v-model="addForm.mobile" type="number"></el-input>
        </el-form-item>
        <el-form-item label="子公司区域：" required="true">
          <div class="selectBox">
            <el-select v-model="province_name" placeholder="请选择" @change="val => sonArea(0, val)">
            <el-option v-for="(item, index) in area" :key="index" :label="item.name" :value="item.name"></el-option>
          </el-select>
          <el-select v-model="city_name" placeholder="请选择" @change="val => sonArea(1, val)">
            <el-option v-for="(item, index) in cityArr" :key="index" :label="item.name" :value="item.name"></el-option>
          </el-select>
          <el-select v-model="area_name" placeholder="请选择" @change="val => sonArea(2, val)">
            <el-option v-for="(item, index) in areaArr" :key="index" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </div>
        </el-form-item>
        <el-form-item label="经纬度：" required="true">
          <div style="display: flex">
            <el-input disabled v-model="addForm.longitude" placeholder="经度"></el-input>
            <el-input disabled v-model="addForm.latitude" placeholder="纬度"></el-input>
            <el-button type="primary" size="medium" @click="position">定位</el-button>
          </div>
        </el-form-item>
        <el-form-item label="所在地址：" required="true">
          <el-input v-model="addForm.address" disabled placeholder="详细位置"></el-input>
        </el-form-item>
        <el-form-item label="登录密码：" required="true">
          <el-input v-model="addForm.subsidiary_password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAdd = !1">取 消</el-button>
        <el-button type="primary" @click="addSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 地图弹框 -->
    <el-dialog title="提示" :visible.sync="mapFlag" width="50%">
      <div>
        <MapData ref="map" :search="searchAddress" @getValue="getValue" :lng="addForm.longitude" :lat="addForm.latitude"></MapData>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getAddress">确 定</el-button>
      </span>
    </el-dialog>
    </div>
</template>

<script>
import _ from 'lodash';
import Paging from '@/components/paging';
import { matchDataAssign } from '@/util/util';
import { getDateformat } from '@/util/getDate';
import MapData from '../../views/store/components/Map';

export default {
  components: {
    Paging,
    MapData,
  },
  data() {
    return {
      list: [],
      total_number: 0,
      searchForm: {
        page: 1,
        rows: 10,
        property_id: '',
        subsidiary_name: '',
        duty_name: '',
        mobile: '',
        province_id: '',
        city_id: '',
        area_id: [],
        start_time: '',
        end_time: '',
      },
      propertyList: [],
      showAdd: !1,
      type: 0,
      id: '',
      addForm: {
        property_id: [],
        subsidiary_name: '',
        duty_name: '',
        mobile: '',
        province_id: '',
        city_id: '',
        area_id: '',
        longitude: '',
        latitude: '',
        address: '',
        subsidiary_password: '',
      },
      province_name: '',
      city_name: '',
      area_name: '',
      // 省级数据
      area: [],
      // 市级数据
      cityArr: [],
      // 区级数据
      areaArr: [],
      searchAddress: '',
      flag: false,
      mapFlag: false,
    };
  },
  created() {
    this.getList();
    this.getArea();
    this.getProperty();
  },
  methods: {
    // 开启地图选择定位
    position() {
      if (!this.addForm.province_id || !this.addForm.city_id || !this.addForm.area_id) {
        this.$message({
          message: '请选择完整地理区域',
          type: 'warning ',
        });
        return;
      }
      this.searchAddress =
        this.area.find(item => item.id == this.addForm.province_id).name +
        this.cityArr.find(item => item.id == this.addForm.city_id).name +
        this.areaArr.find(item => item.id == this.addForm.area_id).name +
        this.addForm.address;
      this.mapFlag = true;
      this.$nextTick(() => {
        this.$refs.map.showDo();
      });
    },
    getValue(item) {
      this.searchAddress = item.value;
    },
    // 获取省市区信息
    getArea() {
      this.$axios.get(this.$api.shopDiy.areaList, {tree: 1, level: 2}).then(res => {
        if (res.code == 0) {
          this.area = res.result.list;
          this.flag = true;
        }
      });
    },
    getProperty() {
      this.$axios.post(this.$api.repair.property.lists).then(res => {
        if (res.code === 0) {
          this.propertyList = res.result;
        }
      });
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        property_id: '',
        subsidiary_name: '',
        duty_name: '',
        mobile: '',
        province_id: '',
        city_id: '',
        area_id: [],
        start_time: '',
        end_time: '',
      };
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    dealSearchData() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.area_id.length) {
        searchForm.province_id = searchForm.area_id[0];
        searchForm.city_id = searchForm.area_id[1];
        searchForm.area_id = searchForm.area_id[2];
      }
      return searchForm;
    },
    getList() {
      let searchForm = this.dealSearchData();
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.repair.Subsidiary.list, searchForm).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          for (let i in list) {
            list[i].property_id = JSON.parse(list[i].property_ids).map(Number);
          }
          // console.log(list);
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 三级联动选择地区
    sonArea(status, val) {
      if (status == 0) {
        let index = this.area.find(item => item.name == val);
        this.addForm.province_id = index.id;
        this.addForm.city_id = null;
        this.cityArr = index._child;
        this.city_name = null;
        this.addForm.area_id = null;
        this.areaArr = [];
        this.area_name = null;
        this.addForm.address = '';
        this.addForm.longitude = '';
        this.addForm.latitude = '';
      } else if (status == 1) {
        let index = this.cityArr.find(item => item.name == val);
        this.addForm.city_id = index.id;
        this.city_name = index.name;
        this.addForm.area_id = null;
        this.areaArr = index._child;
        this.area_name = null;
        this.addForm.address = '';
        this.addForm.longitude = '';
        this.addForm.latitude = '';
      } else if (status == 2) {
        let index = this.areaArr.find(item => item.name == val);
        this.addForm.area_id = index.id;
        this.addForm.address = '';
        this.addForm.longitude = '';
        this.addForm.latitude = '';
      }
    },
    // 获取选中的经纬度
    getAddress() {
      let obj = this.$refs.map.currentCoordinate;
      let item = this.$refs.map.address;
      this.addForm.latitude = obj.lat;
      this.addForm.longitude = obj.lng;
      this.addForm.address = item.street + item.streetNumber + this.searchAddress;
      this.mapFlag = false;
    },
    add() {
      this.type = 0;
      this.showAdd = !0;
      this.addForm = {
        property_id: [],
        subsidiary_name: '',
        duty_name: '',
        mobile: '',
        province_id: '',
        city_id: '',
        area_id: '',
        longitude: '',
        latitude: '',
        address: '',
        subsidiary_password: '',
      };
      this.province_name = '';
      this.cityArr = [];
      this.city_name = '';
      this.areaArr = [];
      this.area_name = '';
    },
    // 调用接口时检测数据是否合法
    checkDataLegitimacy () {
      if (this.addForm.property_id.length == 0) {
        this.$message.warning('请选择物业');
        this.activeTabs = 'one';
        return false;
      }
      if (!this.addForm.subsidiary_name) {
        this.$message.warning('请填写子公司名称');
        this.activeTabs = 'one';
        return false;
      }
      if (!this.addForm.duty_name) {
        this.$message.warning('请填写负责人姓名');
        this.activeTabs = 'one';
        return false;
      }
      if (!this.addForm.mobile) {
        this.$message.warning('请填写联系电话');
        this.activeTabs = 'one';
        return false;
      }
      if (!this.addForm.province_id || !this.addForm.city_id || !this.addForm.area_id) {
        this.$message.warning('请选择子公司区域');
        this.activeTabs = 'one';
        return false;
      }
      if (!this.addForm.longitude || !this.addForm.latitude) {
        this.$message.warning('请选择经纬度');
        this.activeTabs = 'one';
        return false;
      }
      if (!this.addForm.subsidiary_password) {
        this.$message.warning('请填写登录密码');
        this.activeTabs = 'one';
        return false;
      }
    },
    addSubmit() {
      let checkRes = this.checkDataLegitimacy();
      if (checkRes === false) return;
      let url = this.type ? this.$api.repair.Subsidiary.edit : this.$api.repair.Subsidiary.add;
      let data = {
        property_id: this.addForm.property_id,
        subsidiary_name: this.addForm.subsidiary_name,
        duty_name: this.addForm.duty_name,
        mobile: this.addForm.mobile,
        province_id: this.addForm.province_id,
        city_id: this.addForm.city_id,
        area_id: this.addForm.area_id,
        longitude: this.addForm.longitude,
        latitude: this.addForm.latitude,
        address: this.addForm.address,
        subsidiary_password: this.addForm.subsidiary_password,
      };
      if (this.type) data.id = this.id;
      this.$axios.post(url, data).then(res => {
        if (res.code == 0) {
          this.showAdd = !1;
          this.getList();
          this.$message({
            message: `${this.type ? '编辑成功' : '添加成功'}`,
            type: 'success',
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    edit(row) {
      this.type = 1;
      this.showAdd = !0;
      this.addFrom = matchDataAssign(this.addForm, row);
      this.id = row.id;
      this.province_name = this.area.find(item => item.id == this.addFrom.province_id).name;
      this.cityArr = this.area.find(item => item.id == this.addFrom.province_id)._child;
      this.city_name = this.cityArr.find(item => item.id == this.addFrom.city_id).name;
      this.areaArr = this.cityArr.find(item => item.id == this.addFrom.city_id)._child;
      this.area_name = this.areaArr.find(item => item.id == this.addFrom.area_id).name;
    },
    remove(id, index) {
      this.$confirm('确认删除此子公司？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.repair.Subsidiary.del, {
            id: id,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message({
                message: '删除成功',
                type: 'success',
              });
              this.list.splice(index, 1);
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.selectBox {
    display: flex;
    .el-select {
      flex: 1;
      /deep/ .el-input {
        width: 100% !important;
      }
    }
  }
.el-main {
  background: #fff;
}
.el-form-search {
  margin-bottom: 25px;
}
</style>
